<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Assistant" subtitle="Manage access and permissions for the Assistant app">

		<app-content-head-actions-item text="Open" icon="assistant" v-on:click="onOpenClick" />
		<app-content-head-actions-item v-if="$authorised('con/assistant/access', 'write')" text="Create" icon="create" v-on:click="onCreateClick" :divide="true" />
		<app-content-head-actions-item v-if="$authorised('con/assistant/access', 'write')" text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no logins found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Name" />
				<app-table-column text="Access" />
				<app-table-column text="Code" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-status :status="item.status" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="$constants.assistant.login.types[item.type]" />
					<app-table-cell-text text="-" v-if="item.type === $constants.assistant.login.type.account" />
					<app-table-cell-text :text="item.code" v-if="item.type === $constants.assistant.login.type.code" />

					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '16px auto 120px 120px 24px',
			endpoint: 'convention/assistant/logins',
			live: 'convention/assistant/login',
			edit: 'Convention.Assistant.Logins.Edit'
		}

	},

	methods: {

		onOpenClick: function() {

			window.open(process.env.VUE_APP_ASSISTANT, '_blank').focus();

		}

	}

}

</script>

<style scoped>

</style>